import { DOMAIN } from '../../data/enums/config';
import { getUserRole } from '../../data/dao/user';
import { ADMIN_ROLE_GROUP, DISTRIBUTOR_ASSOCIATED_ROLES } from '../../data/enums/UserRole';

const getModulesDisplayStatus = (domain, permission) => {
  const userRole = getUserRole();
  switch (domain) {
    case DOMAIN.OPENING_BALANCE:
    case DOMAIN.STOCK_ADJUSTMENT:
    case DOMAIN.STOCK_LEDGER:
    case DOMAIN.STOCK_AVAILABILITY:
      if (permission.read && (ADMIN_ROLE_GROUP.includes(userRole) || DISTRIBUTOR_ASSOCIATED_ROLES.includes(userRole))) {
        return {
          distributorSelection: true,
        };
      }
      return {};
    default:
      return {};
  }
};

/** Inventory * */

const purchaseReturn = {
  title: 'Purchase Return',
  domain: DOMAIN.PURCHASE_RETURN,
  apiTitles: {
    downloadSummary: 'downloadPurchaseReturnDetail',
  },
};

const salesReturn = {
  title: 'Returns',
  domain: DOMAIN.SALES_RETURN,
  apiTitles: {
    // downloadSummary: 'downloadPurchaseReturnDetail',
  },
};

const stockLedger = {
  title: 'Stock Ledger',
  domain: DOMAIN.STOCK_LEDGER,
  apiTitles: {
    downloadSummary: 'downloadStockLedgers',
  },
  componentsDisplay: permission => getModulesDisplayStatus(DOMAIN.STOCK_LEDGER, permission),
};

const wareHouse = {
  title: 'Ware House Management',
  domain: DOMAIN.WARE_HOUSE,
  apiTitles: {
    downloadSummary: 'downloadWareHOuse',
  },
  componentsDisplay: permission => getModulesDisplayStatus(DOMAIN.WARE_HOUSE, permission),
};
const warehouseReport = {
  title: 'Warehouse Report',
  domain: DOMAIN.WAREHOUSE_REPORT,
  apiTitles: {},
};
const warehouseInventory = {
  title: 'Warehouse Inventory',
  domain: DOMAIN.WAREHOUSE_INVENTORY,
  apiTitles: {},
};

const openingBalance = {
  title: 'Opening Balance',
  domain: DOMAIN.OPENING_BALANCE,
  apiTitles: {},
  componentsDisplay: permission => getModulesDisplayStatus(DOMAIN.OPENING_BALANCE, permission),
};

const stockAdjustment = {
  title: 'Stock Adjustment',
  domain: DOMAIN.STOCK_ADJUSTMENT,
  apiTitles: {},
  componentsDisplay: permission => getModulesDisplayStatus(DOMAIN.STOCK_ADJUSTMENT, permission),
};
/** Inventory * */

/** Configuration * */

const town = {
  title: 'Town',
  domain: DOMAIN.TOWN,
  apiTitles: {},
};

const shopperBasedDesign = {
  title: 'SBD',
  domain: DOMAIN.SBD,
  apiTitles: {},
};

export const getShopperBasedDesign = ({ title = 'SBD' }) => {
  return {
    title,
    domain: DOMAIN.SBD,
    apiTitles: {},
  };
};

const FOCUSED_SKU = {
  title: 'Focused SKU',
  domAin: DOMAIN.FOCUSED_SKU,
  apiTitles: {},
};

const STOCK_COUNT = {
  title: 'Stock Count',
  domAin: DOMAIN.STOCK_COUNT,
  apiTitles: {},
};

const territory = {
  title: 'Territory',
  domain: DOMAIN.TERRITORY,
  apiTitles: {},
};

const channelCategory = {
  title: 'Channel',
  domain: DOMAIN.CHANNEL_CATEGORY,
  apiTitles: {},
};

const userRole = {
  title: 'User Role',
  domain: DOMAIN.CHANNEL_CATEGORY,
  apiTitles: {},
};

const userAccount = {
  title: 'User Account',
  domain: DOMAIN.USER_ACCOUNT,
  apiTitles: {},
};

const subD = {
  title: 'Sub D',
  domain: DOMAIN.SUB_D,
  apiTitles: {},
};

const channelAssortment = {
  title: 'Channel Assortment',
  domain: DOMAIN.CHANNEL_ASSORTMENT,
  apiTitle: {},
};

const replenishment = {
  title: 'Replenishment',
  domain: DOMAIN.REPLENISHMENT,
  apiTitle: {},
};

const subdStockAvailability = {
  title: 'Sub D Stock Availability',
  domain: DOMAIN.STOCK_AVAILABILITY,
  apiTitles: {},
  componentsDisplay: permission => getModulesDisplayStatus(DOMAIN.STOCK_AVAILABILITY, permission),
};

const subdStockReplenishment = {
  title: 'Sub D Replenishment Report',
  domain: DOMAIN.STOCK_REPLENISHMENT,
  apiTitle: {},
};

const stockReplacement = {
  title: 'Stock Replacement Report',
  domain: DOMAIN.STOCK_REPLACEMENT,
  apiTitle: {},
};

const skuCoverage = {
  title: 'SKU Coverage',
  domain: DOMAIN.SKU_COVERAGE,
  apiTitle: {},
};

const brandCoverage = {
  title: 'Brand Coverage',
  domain: DOMAIN.BRAND_COVERAGE,
  apiTitle: {},
};

const ico = {
  title: 'ICO',
  domain: DOMAIN.ICO,
};
/** Configuration * */

const RETURN_TYPE = {
  PARTIAL: 'PARTIAL',
  COMPLETE: 'COMPLETE',
};
const STOCK_TYPE = {
  SALEABLE: { title: 'Sellable', value: 'SELLABLE' },
  DAMAGED: { title: 'Damaged', value: 'DAMAGED' },
  EXPIRED: { title: 'Expired', value: 'EXPIRED' },
};
const DISTRIBUTOR_SERVICES = {
  LOGISTICS: 'logistics',
  BILLING: 'billing',
  DELIVERY: 'delivery',
};
const DefaultDistributorServices = [
  DISTRIBUTOR_SERVICES.LOGISTICS,
  DISTRIBUTOR_SERVICES.BILLING,
  DISTRIBUTOR_SERVICES.DELIVERY,
];
const CATALOG_HIERARCHY = {
  PRINCIPAL: 1,
  PRODUCT_GROUP: 2,
  BRAND: 3,
  MARKET_CATALOG: 4,
  SUB_BRAND: 5,
  SUB_BRAND_FORM: 6,
  SKU_FAMILY: 7,
};

export {
  DefaultDistributorServices,
  DISTRIBUTOR_SERVICES,
  purchaseReturn,
  RETURN_TYPE,
  stockLedger,
  wareHouse,
  warehouseReport,
  warehouseInventory,
  territory,
  town,
  channelCategory,
  userRole,
  STOCK_TYPE,
  salesReturn,
  shopperBasedDesign,
  openingBalance,
  userAccount,
  subD,
  FOCUSED_SKU,
  stockAdjustment,
  channelAssortment,
  CATALOG_HIERARCHY,
  replenishment,
  subdStockAvailability,
  subdStockReplenishment,
  stockReplacement,
  skuCoverage,
  brandCoverage,
  ico,
  STOCK_COUNT,
};
