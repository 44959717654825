import { Switch } from '../../../../v4/components';
import { Sequence } from './Sequence';
import { CustomSelect, Input } from '../../../../components';

export const OrderTakingSequence = ({ ...props }) => {
  const {
    onInputChange,
    sequenceData,
    changeDrag,
    onSequenceChange,
    sequenceTitle,
    handleDropDownChange,
    catalogList,
    onFocusedSKUType,
    enableErrorDisplay,
    refsObj,
  } = props;
  const filteredCatalogList = catalogList?.filter?.(ite => ite?.id !== sequenceData?.groupByCatalog?.id) || [];
  return (
    <>
      <div className="form-input-wrap card order">
        <CustomSelect
          clearable={false}
          className="custom-select"
          placeholder="Select"
          labelContent="Group Catalog By *"
          getOptionLabel={option => option.title}
          getOptionValue={option => option.id}
          options={catalogList}
          ref={ref => (refsObj.groupByCatalog = ref)}
          enableErrorDisplay={enableErrorDisplay}
          enableValidation
          value={catalogList?.filter(ite => ite?.id === sequenceData?.groupByCatalog?.id)}
          onChange={e => {
            handleDropDownChange(e, ['bcpData', 'groupByCatalog'], handleDropDownChange);
          }}
        />
        <Switch
          checked={sequenceData?.paginationBySelectedGroup}
          labelContent="Enable Paginations  By Selected Group"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'paginationBySelectedGroup',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'paginationBySelectedGroup',
              ['bcpData', 'paginationBySelectedGroup'],
            );
          }}
        />
        <>
          <CustomSelect
            name="groupBySku"
            clearable={false}
            className="custom-select"
            placeholder="Select"
            labelContent="Group SKU's By *"
            getOptionLabel={option => option.title}
            getOptionValue={option => option.id}
            options={filteredCatalogList}
            enableValidation
            value={filteredCatalogList?.filter(ite => ite?.id === sequenceData?.groupBySku?.id)}
            onChange={e => {
              handleDropDownChange(e, ['bcpData', 'groupBySku'], handleDropDownChange);
            }}
            ref={ref => (refsObj.groupBySku = ref)}
            enableErrorDisplay={enableErrorDisplay}
          />
        </>
      </div>
      <div className="form-input-wrap card order distributionMetric">
        <Switch
          checked={sequenceData?.distributionMetric}
          labelContent="Distribution Metric"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'distributionMetric',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'distributionMetric',
              ['bcpData', 'distributionMetric'],
            );
          }}
        />
        {sequenceData?.distributionMetric && (
          <>
            <Input
              value={sequenceData?.labelName}
              name="labelName"
              type="text"
              labelContent={'Label Name *'}
              placeholder="Label Name"
              enableValidation
              onChange={event => onInputChange(event, 'labelName', ['bcpData', 'labelName'])}
              ref={ref => (refsObj.labelName = ref)}
              enableErrorDisplay={enableErrorDisplay}
            />
          </>
        )}
      </div>

      <div className="form-input-wrap focused-sku card order">
        <Switch
          checked={sequenceData?.focusedSku}
          labelContent="Focused SKU"
          onToggle={(checked, event) => {
            onInputChange(
              {
                formattedValue: !!checked,
                target: {
                  name: 'focusedSku',
                  type: 'boolean',
                  value: !!checked,
                },
              },
              'focusedSku',
              ['bcpData', 'focusedSku'],
            );
          }}
        />
        {sequenceData?.focusedSku && (
          <>
            <div className="permission-choose flex-start">
              <div className="checkbox-horizontal">
                <div className="status-content">
                  <label className="radio-list">
                    <input
                      type="radio"
                      name="general"
                      value="general"
                      checked={sequenceData?.focusedSkuType?.general}
                      onChange={e => onFocusedSKUType(e, 'general')}
                    />
                    <span>General</span>
                  </label>
                  <label className="radio-list">
                    <input
                      type="radio"
                      name="smart"
                      value="smart"
                      checked={sequenceData.focusedSkuType?.smart}
                      onChange={e => onFocusedSKUType(e, 'smart')}
                    />
                    <span>Smart</span>
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Switch
        checked={sequenceData?.enableOrderTakingProcess}
        labelContent="Enable order taking sequence "
        onToggle={(checked, event) => {
          onInputChange(
            {
              formattedValue: !!checked,
              target: {
                name: 'enableOrderTakingProcess',
                type: 'boolean',
                value: !!checked,
              },
            },
            'enableOrderTakingProcess',
            ['enableOrderTakingProcess'],
          );
        }}
        classes="card"
      />
      {sequenceData?.enableOrderTakingProcess && (
        <>
          <Sequence
            sequenceData={sequenceData?.orderTakingProcessSequenceData}
            onInputChange={changeDrag}
            onSequenceChange={onSequenceChange}
            sequenceTitle={sequenceTitle}
          />
        </>
      )}
    </>
  );
};
